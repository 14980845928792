import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gregoryapps',
  templateUrl: './gregoryapps.component.html',
  styleUrls: ['./gregoryapps.component.css']
})
export class GregoryappsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
